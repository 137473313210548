body {
    font-family: $font_copy;
    font-size: $font_0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font_display;
    font-weight: $font_regular;
}

h6 {
    font-weight: $font_bold;
}



p {
    line-height: $line-height_2;
}

ul,
ol {
    line-height: $line-height_3;
}



// Need to move to a point were it can only clean when it is on top of a second build. 
// a,
// a:hover {
// 	color: unset;
// }
kbd,
code,
samp,
var {
    font-family: $font_mono;
}