/* Define the "system" font family */
@font-face {
	font-family: system;
	font-style: normal;
	font-weight: 300;
	src: local('.SFNSText-Light'), local('.HelveticaNeueDeskInterface-Light'), local('.LucidaGrandeUI'),
		local('Ubuntu Light'), local('Segoe UI Light'), local('Roboto-Light'), local('DroidSans'), local('Tahoma');
}

$fonts: (
	accent: (
		name: 'system',
		short-name:"Sys",
		stack: '"Roboto Slab","Times New Roman","Lucida Bright",Georgia,serif, "slab"',
		weight:(
			light:100,
			regular:300,
			medium:500,
			bold:700,
			xbold:900
		)
	),
	display: (
		name: 'system',
		short-name:"Sys",
		stack: '"Maven Pro","Muli","Open Sans","system",sans-serif',
		weight:(
			light:100,
			regular:300,
			medium:500,
			bold:700,
			xbold:900
		)
	),
	copy: (
		name: 'system',
		short-name:"Sys",
		stack: '"Muli","Open Sans","system",sans-serif',
		weight:(
			light:100,
			regular:300,
			medium:500,
			bold:700,
			xbold:900
		)
	),
	ui: (
		name: 'system',
		short-name:"Sys",
		stack: '"Open Sans",-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
		weight:(
			light:100,
			regular:300,
			medium:500,
			bold:700,
			xbold:900
		)
	),
) !default;



html {
	-webkit-font-smoothing: antialiased;
	-webkit-line-break: after-white-space;
	-webkit-locale: 'en';
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	line-break: white-space;
}
