.table {
	
	td,
	th {
		text-align: left;
	}
	tbody tr:nth-of-type(odd) {
		background-color: #13121202; //bg_black-_05
	}
	tr+tr{
		border-top: 1px solid #13121240;//br_black-3
	}
	&.unshaded {
		tbody tr:nth-of-type(odd) {
			background-color: unset;
		}
	}
}
